import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import SEO from '../../components/blocks/SEO';
import SimpleLayout from '../../components/blocks/SimpleLayout/SimpleLayout';
import SignupPage from '../../modules/signup';
import queryString from 'query-string';
import { setUserDetails } from '../../modules/signup/store';

const Page = ({ pathContext, location }) => {
  const parsed = queryString.parse(location.search);
  const u_hash = parsed?.u;

  const dispatch = useDispatch();
  useEffect(() => {
    if (u_hash) {
      dispatch(setUserDetails({ u_hash }));
    }
  }, []);

  return (
    <SimpleLayout>
      <SEO title="Authentic Evergreen Marketing | Deadline Funnel" />
      <SignupPage
        expiresTitle={true}
        signupTitle="Save 25% On Your First Month!"
        confirmTitle="Complete Your Signup"
        leftHeadline="Your Plan Details"
        special={false}
        plan="pro"
        location={location}
        funnel="no-cc"
        forcePaymentForm={true}
        forceTrialInDays={0}
        priceOff={25}
        hideAnnual={true}
        couponId="25-percent-once"
        hidePlanDescription
      />
    </SimpleLayout>
  );
};

export default Page;
